<template>
	<div class="navigator">
		<span class="navigator__1">Expert</span><span> /</span
		><span>Акции</span>
	</div>
	<div class="row">
		<div class="h3">Скидка для пенсионеров</div>
		<img class="img" src="images/Action__main-img-1.jpg" alt="" id="img" />
		<div class="sub-img">
			<div>
				Свое уважение к пожилым людям мы воплотили в возможность
				получения ими специальной скидки в размере 10% на все модели
			</div>
			<button class="get-discount">
				<div>Получить скидку</div>
				<img
					class="get-discount__arrow-img"
					src="images/Arrow 9.svg"
					alt=""
				/><img
					class="get-discount__circle-img"
					src="images/btn-circle-img.svg"
					alt=""
				/>
			</button>
		</div>
	</div>
	<div class="row">
		<div class="h3">Вместе дешевле</div>
		<img
			class="img"
			src="images/Action__main-img-2.jpg"
			alt=""
			id="img-2"
		/>
		<div class="sub-img">
			<div>Объеденяйтесь с соседями и получите скидку в размере 10%</div>
			<button class="get-discount">
				<div>Получить скидку</div>
				<img
					class="get-discount__arrow-img"
					src="images/Arrow 9.svg"
					alt=""
				/><img
					class="get-discount__circle-img"
					src="images/btn-circle-img.svg"
					alt=""
				/>
			</button>
		</div>
	</div>
	<div class="row row_3">
		<div class="h3">Скидка для новоселов</div>
		<img
			class="img"
			src="images/Action__main-img-3.jpg"
			alt=""
			id="img-3"
		/>
		<div class="sub-img">
			<div>
				Всем новоселам даем особо выгодные условия — двери со скидкой 7%
			</div>
			<button class="get-discount">
				<div>Получить скидку</div>
				<img
					class="get-discount__arrow-img"
					src="images/Arrow 9.svg"
					alt=""
				/><img
					class="get-discount__circle-img"
					src="images/btn-circle-img.svg"
					alt=""
				/>
			</button>
		</div>
	</div>
</template>
